<template>
  <a-page-header @back="() => $router.go(-1)" title="PARÁMETROS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Configuración</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Parámetros</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <!--------------- Filtros--------------->
    <fieldset class="border border-solid w-full md:w-1/2 border-gray-300 p-3 px-8 lg:w-auto">
      <legend class="text-sm text-center">Filtros</legend>
      <ParametrosFiltros :currentPage="currentPage" @fetchParametros="fetchParametros" />
    </fieldset>

    <!--------------- Acciones--------------->
    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto">
      <legend class="text-sm text-center">Acciones</legend>

      <ParametrosAcciones
        :parametro="parametro"
        :isDisabled="isDisabled"
        @handleAdd="handleAdd"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @handleChangeState="handleChangeState"
        @showModalLogs="showModalLogs"
      />
    </fieldset>
  </div>

  <!--------------- Tabla --------------->
  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="parametros"
      size="small"
      :pagination="false"
      :custom-row="customRow"
      rowKey="idparametro"
      :loading="isLoading"
      :rowClassName="(_record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ index }">
        <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Activado" : "Desactivado" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <footer class="flex flex-wrap items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex flex-wrap">
      <a-pagination
        v-model:current="currentPage"
        :defaultCurrent="1"
        @change="onChangePage"
        v-model:pageSize="pageSize"
        :total="totalDocs"
      />

      <button class="ml-4" @click="fetchParametros">
        <ReloadOutlined />
      </button>
    </div>

    <p>Total de registros: {{ totalDocs }}</p>
  </footer>

  <!--------------- Modal--------------->
  <ParametrosAddEdit
    v-if="isVisible"
    :isVisible="isVisible"
    :parametro="parametro"
    @closeModal="closeModal"
    @fetchParametros="fetchParametros"
    @clearSelectedArrays="clearSelectedArrays"
  />

  <SistemaLog
    v-if="isVisibleLogs"
    :isVisible="isVisibleLogs"
    @closeModal="closeModal"
    @clearSelectedArrays="clearSelectedArrays"
  />
</template>

<script>
import { ref, watch, reactive, computed, toRaw, onMounted, createVNode, onUnmounted } from "vue";
import { useStore } from "vuex";
import { columns } from "./utilsParametros";
import ParametrosFiltros from "./ParametrosFiltros.vue";
import ParametrosAddEdit from "./ParametrosAddEdit.vue";
import ParametrosAcciones from "./ParametrosAcciones.vue";
import ParametrosApi from "@/api/parametros";
import UsuariosApi from "@/api/user";
import SistemaLog from "./SistemaLog.vue";
import { Modal, notification } from "ant-design-vue";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default {
  name: "AppParametros",
  components: {
    ParametrosFiltros,
    ParametrosAcciones,
    SistemaLog,
    // icons
    ReloadOutlined,
    ParametrosAddEdit
  },
  setup() {
    const store = useStore();
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const isVisibleLogs = ref(false);
    const isVisible = ref(false);
    const parametros = ref([]);
    const isLoading = ref(false);
    const parametro = ref(null);
    const state = reactive({
      selectedRowKeys: [],
      rowActiveStatus: []
    });
    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });

    const currentPage = store.getters["parametros/currentPage"];
    const { establecimiento } = store.state.user.usuario;

    // ------- trae datos de la api --------
    const fetchParametros = () => {
      const filter = store.getters["parametros/filter"];
      const search = store.getters["parametros/search"];
      const currentPage = store.getters["parametros/currentPage"];

      isLoading.value = true;
      ParametrosApi.getAll({ currentPage, filter, search })
        .then((response) => {
          console.log("PARAMETROS", response);
          parametros.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Configuración", "Parametros", establecimiento);
      fetchParametros();
    });

    onUnmounted(() => {
      store.dispatch("parametros/setFilter", "all");
      store.dispatch("parametros/setSearch", "");
      store.dispatch("parametros/setCurrentPage", 1);
    });

    // -------- se ejecuta cuando selecciona una fila --------
    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          parametro.value = toRaw(selectedRows[0]);
        }
      };
    });

    // ------- desactiva la seleccion actual -------
    const customRow = () => {
      return {
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const showModalLogs = () => {
      isVisibleLogs.value = true;
    };

    const onChangePage = (newPage) => {
      store.dispatch("parametros/setCurrentPage", newPage);
      fetchParametros();
      clearSelectedArrays();
    };

    watch(state, () => {
      isDisabled.edit = state.selectedRowKeys.length !== 1;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
      isVisibleLogs.value = false;
    };

    // ------- acciones -------
    const handleAdd = () => {
      parametro.value = {
        idmodulo: "",
        denominacion: "",
        valor: ""
      };
      showModal();
    };

    const handleEdit = () => {
      showModal();
    };

    const handleChangeState = () => {
      const activateMsg = parametro.value.activo === "1" ? "desactivará" : "activará";
      const actionMsg = parametro.value.activo === "1" ? "desactivado" : "activado";

      Modal.confirm({
        title: () => "¿Estás seguro de cambiar el estado?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se ${activateMsg} el parámetro ${parametro.value.denominacion}`,
        okText: () => "Sí, cambiar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const payload = {
            ids: toRaw(state.selectedRowKeys),
            activos: [parametro.value.activo === "1" ? "0" : "1"]
          };

          ParametrosApi.changeStateMany(payload)
            .then(() => {
              fetchParametros();
              clearSelectedArrays();
              notification.success({
                message: "Operación exitosa",
                description: `Se ha ${actionMsg} el parámetro satistactoriamente.`
              });
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
      state.rowActiveStatus = [];
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará el parámetro ${parametro.value.denominacion}`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          ParametrosApi.deleteMany(toRaw(state.selectedRowKeys))
            .then(() => {
              fetchParametros();
              clearSelectedArrays();
              notification.success({
                message: "Operación exitosa",
                description: `Se ha eliminado el parámetro satistactoriamente.`
              });
            })
            .catch((err) => console.log(err));
        }
      });
    };

    return {
      isLoading,
      parametros,
      columns,
      customRow,
      rowSelection,
      handleAdd,
      handleEdit,
      handleDelete,
      handleChangeState,
      pageSize,
      currentPage,
      totalDocs,
      isVisible,
      closeModal,
      fetchParametros,
      isVisibleLogs,
      parametro,
      showModalLogs,
      isDisabled,
      clearSelectedArrays,
      onChangePage
    };
  }
};
</script>

<style></style>
