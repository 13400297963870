<template>
  <button @click="handleAdd" class="text-center px-3">
    <div class="text-3xl text-green-500">
      <FileAddOutlined />
    </div>
    <p>Nuevo</p>
  </button>

  <button @click="handleEdit" class="text-center px-3" :disabled="isDisabled.edit">
    <div
      class="text-3xl"
      :class="[isDisabled.edit ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400']"
    >
      <FormOutlined />
    </div>
    <p>Editar</p>
  </button>

  <button
    @click="handleChangeState"
    class="text-center px-3"
    :disabled="isDisabled.deleteAndChange"
  >
    <div
      class="text-3xl"
      :class="[isDisabled.deleteAndChange ? 'text-gray-400 cursor-not-allowed' : 'text-yellow-400']"
    >
      <IssuesCloseOutlined />
    </div>
    <p>{{ actionMessage }}</p>
  </button>

  <button @click="showModalLogs" type="submit" class="text-center px-3">
    <div class="text-3xl text-green-600">
      <BugOutlined />
    </div>
    <p>Error Log</p>
  </button>
</template>

<script>
import {
  FileAddOutlined,
  FormOutlined,
  // DeleteOutlined,
  IssuesCloseOutlined,
  BugOutlined
} from "@ant-design/icons-vue";
import { computed } from "vue";

export default {
  props: {
    isDisabled: {
      type: Object,
      default() {
        return {
          edit: true,
          deleteAndChange: true
        };
      },
      required: true
    },
    parametro: {
      type: Object
    }
  },
  components: {
    FileAddOutlined,
    FormOutlined,
    IssuesCloseOutlined,
    BugOutlined
  },
  emits: ["handleAdd", "handleEdit", "handleDelete", "handleChangeState", "showModalLogs"],
  setup(props, { emit }) {
    const actionMessage = computed(() =>
      props.parametro?.activo === "1" ? "Desactivar" : "Activar"
    );

    return {
      actionMessage,
      handleAdd: () => emit("handleAdd"),
      handleEdit: () => emit("handleEdit"),
      handleDelete: () => emit("handleDelete"),
      handleChangeState: () => emit("handleChangeState"),
      showModalLogs: () => emit("showModalLogs")
    };
  }
};
</script>

<style></style>
