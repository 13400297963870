export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "MÓDULO",
    dataIndex: "modulo",
    key: "modulo",
    width: 150
  },
  {
    title: "NOMBRE",
    dataIndex: "denominacion",
    key: "denominacion",
    width: 220
  },
  {
    title: "VALOR",
    dataIndex: "valor",
    key: "valor",
    ellipsis: true,
    width: 220
  },
  {
    title: "DESCRIPCIÓN",
    dataIndex: "descripcion",
    key: "descripcion",
    width: 220
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" },
    width: 120
  }
];
