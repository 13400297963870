<template>
  <a-modal
    :visible="isVisible"
    class="max-w-sm md:max-w-xl"
    width="100%"
    title="Logs de usuario"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="$emit.closeModal"
    :maskClosable="false"
  >
    <a-form :model="formState" ref="formRef" layout="vertical">
      <a-form-item label="Selecciona los rangos de fechas" name="periodo">
        <a-range-picker v-model:value="formState.fechas" format="DD/MM/YYYY" class="w-full" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive } from "vue";
import ApiDescargas from "@/api/descargas";
import moment from "moment";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closeModal", "clearSelectedArrays"],
  setup(props, { emit }) {
    const formState = reactive({
      fechas: ""
    });

    const handleOk = async () => {
      const fecha_ini = formState.fechas[0].format("YYYY-MM-DD") + " 00:00:00";
      const fecha_fin =
        formState.fechas[1].format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss");

      ApiDescargas.getReporteErrorLog(fecha_ini, fecha_fin)
        .then(() => {})
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      emit("clearSelectedArrays");
      emit("closeModal");
    };

    return {
      handleCancel,
      handleOk,
      formState
    };
  }
};
</script>
