<template>
  <a-form
    layout="horizontal"
    :model="formState"
    :rules="rules"
    ref="formRefFilter"
    class="flex flex-wrap items-center"
  >
    <div class="flex flex-col">
      <a-form-item label="Estado" name="filter" class="mb-2">
        <a-select v-model:value="formState.filter">
          <a-select-option value="all">Todos</a-select-option>
          <a-select-option value="1">Activos</a-select-option>
          <a-select-option value="0">Desactivados</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Buscar" class="mb-0" name="search">
        <a-input v-model:value="formState.search" />
      </a-form-item>
    </div>

    <div>
      <button @click="applyFilters" type="submit" class="text-center px-3">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    SearchOutlined,

    ClearOutlined
  },

  emits: ["fetchParametros", "showModalLogs"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);

    const formState = reactive({
      filter: store.getters["parametros/filter"],
      search: store.getters["parametros/search"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { filter, search } = toRaw(formState);
          store.dispatch("parametros/setFilter", filter);
          store.dispatch("parametros/setSearch", search);
          store.dispatch("parametros/setCurrentPage", 1);
          emit("fetchParametros");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.filter = "all";
      formState.search = "";

      store.dispatch("parametros/setCurrentPage", 1); // lo manda a la pagina 1
      store.dispatch("parametros/cleanFilters");
      emit("fetchParametros");
    };

    const applyCSV = () => {
      emit("showModalLogs");
    };

    return {
      formState,
      applyFilters,
      applyCSV,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled
    };
  }
};
</script>
