<template>
  <a-modal
    :visible="true"
    :title="[isEdit ? 'Editar parámetro' : 'Nuevo parámetro']"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="max-w-sm"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form :model="formState" :rules="rules" ref="formRef" v-bind="layout">
      <a-form-item label="Módulo" name="idmodulo">
        <a-select v-model:value="formState.idmodulo">
          <a-select-option value="" disabled="">Seleccione el módulo</a-select-option>
          <a-select-option
            :value="modulo.idmodulo"
            v-for="modulo in modulos"
            :key="modulo.idmodulo"
          >
            {{ modulo.denominacion }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Nombre" name="denominacion">
        <a-input v-model:value="formState.denominacion" :disabled="isEdit" placeholder="ejm. PI" />
      </a-form-item>
      <a-form-item label="Valor" name="valor">
        <a-textarea v-model:value="formState.valor" placeholder="ejm. 3.1416" />
      </a-form-item>
      <a-form-item label="Descripción" name="descripcion">
        <a-textarea
          v-model:value="formState.descripcion"
          placeholder="ejm. Sirve para hacer los cálculos"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, onMounted, toRaw, ref, computed } from "vue";
import { notification } from "ant-design-vue";
import ParametrosApi from "@/api/parametros";

export default {
  props: {
    parametro: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchParametros", "clearSelectedArrays"],
  setup(props, { emit }) {
    const modulos = ref([]);
    const formRef = ref();
    /* eslint-disable  vue/no-setup-props-destructure*/
    const parametro = props.parametro; // se define porque tiene que ser unico en editar
    // const isEdit = !!props.parametro.idparametro;
    const denominacion = props.parametro.denominacion;

    const isEdit = computed(() => !!props.parametro.idparametro);

    const formState = reactive(parametro);

    const closeModal = () => {
      emit("closeModal");
    };

    const fetchModulos = () => {
      ParametrosApi.getModulos()
        .then((response) => {
          modulos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      fetchModulos();
    });

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";
          try {
            if (isEdit.value) {
              const isUnique = denominacion !== formState.denominacion;
              const idparametro = toRaw(formState)?.idparametro;

              response = await ParametrosApi.editOne(payload, idparametro, isUnique);
              msgNotificacion = "El parámetro ha sido modificado correctamente.";
            } else {
              response = await ParametrosApi.addOne(payload);
              msgNotificacion = "El parámetro ha sido creado satisfactoriamente.";
            }

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });
            }
            emit("closeModal");
            emit("fetchParametros");
            emit("clearSelectedArrays");
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: "El parámetro ya se encuentra registrado"
              // description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      emit("clearSelectedArrays");
      emit("fetchParametros");
      emit("closeModal");
    };

    const rules = {
      idmodulo: [
        { required: true, message: "El campo módulo debe ser seleccionado", trigger: "blur" }
      ],
      denominacion: [
        {
          required: true,
          message: "El campo nombre no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ],
      valor: [
        {
          required: true,
          message: "El campo valor no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 350,
          message: "La cantidad máxima de caracteres es de 350",
          trigger: "blur"
        }
      ],
      description: [
        {
          max: 250,
          message: "La cantidad máxima de caracteres es de 250",
          trigger: "blur"
        }
      ]
    };

    const layout = {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      }
    };
    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      modulos,
      formRef,
      layout,
      rules,
      isEdit
    };
  }
};
</script>
